import React from 'react'
import {StaticQuery, graphql} from "gatsby"




const Image = ({ children }) => {
    const imageQuery = graphql`
        query Image {
            allImagesCsv {
                edges {
                    node {
                        id
                        contentUrl
                        linkUrl
                        description

                    }
                }
            }
        }
    `

    const ImageWrapped = data => {
        return children.map(child => {
            const imageData = data.allImagesCsv.edges.filter(edge => {
                return edge.node.id === child
            })
            return imageData.map(edge => {
                return <img alt={edge.node.description} key={edge.node.id} src={edge.node.contentUrl}></img>
            })
        })
    }

    return (
        <StaticQuery query={imageQuery}
            render={ImageWrapped}
        />
    )
}

export default Image
