import React from 'react'
import {StaticQuery, graphql} from "gatsby"




const Thumbs = ({ children }) => {
    const thumbsQuery = graphql`
        query Thumbs {
            allImagesCsv {
                edges {
                    node {
                        id
                        contentUrl
                        linkUrl
                        description

                    }
                }
            }
        }
    `

    const ThumbsWrapped = data => {
        return children.map(childString => {
            return childString.split('|').map(child => {
                    const imageData = data.allImagesCsv.edges.filter(edge => {
                    return edge.node.id === child
                })
                return imageData.map(edge => {
                    return <img alt={edge.node.description} key={edge.node.id}  src={edge.node.contentUrl}></img>
                })
            })
        })
    }

    return (
        <StaticQuery query={thumbsQuery}
            render={ThumbsWrapped}
        />
    )
}

export default Thumbs
