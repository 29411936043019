import React from 'react'
import {StaticQuery, graphql} from "gatsby"




const Album = ({ children }) => {
    const albumQuery = graphql`
        query Album {
            allImagesCsv {
                edges {
                    node {
                        id
                        albumId
                        contentUrl
                        linkUrl
                        description

                    }
                }
            }
        }
    `

    const AlbumWrapped = data => {
        return children.map(childString => {
            return childString.split('|').map(child => {
                    const imageData = data.allImagesCsv.edges.filter(edge => {
                    return edge.node.albumId === child
                })
                return imageData.map(edge => {
                    return <img alt={edge.node.description} key={edge.node.id} src={edge.node.contentUrl}></img>
                })
            })
        })
    }

    return (
        <StaticQuery query={albumQuery}
            render={AlbumWrapped}
        />
    )
}

export default Album
