import React from "react"
import { Link as GLink, graphql } from "gatsby"
import rehypeReact from "rehype-react"
import rehypeParse from "rehype-parse"
import unified from "unified"
import Link from "@material-ui/core/Link"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Thumbs from "../components/thumbs"
import Album from "../components/album"

class BlogPostTemplate extends React.Component {
  renderComponents = html => {
    const htmlToReype = unified()
      .use(rehypeParse, {
        fragment: true,
      })
      .parse(html)

    const compiler = new rehypeReact({
      createElement: React.createElement,
      components: {
        iimage: Image,
        thumbs: Thumbs,
        album: Album,
        a: Link,
      },
    }).Compiler

    return compiler(htmlToReype)
  }

  render() {
    const post = this.props.data.blogPost
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const disqusConfig = {
      shortname: "psykar",
      config: { identifier: post.id, title: post.frontmatter.title },
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1
          style={{
            marginBottom: 0,
          }}
        >
          {post.frontmatter.title}
        </h1>
        <p
          style={{
            display: `block`,
          }}
        >
          {post.frontmatter.date}
        </p>
        {this.renderComponents(post.html)}
        <hr />
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.slug} rel="prev" component={GLink}>
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="next" component={GLink}>
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <DiscussionEmbed {...disqusConfig} />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    blogPost(slug: { eq: $slug }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
